import { BigNumber } from '@ethersproject/bignumber'
import { CurrencyAmount, Token } from '@sushiswap/core-sdk'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { useActiveWeb3React } from 'app/services/web3'
import { useTransactionAdder } from 'app/state/transactions/hooks'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAaveOracleContract, useSculptorATokenContract } from '..'
import { useSculptorChefIncentivesControllerContract, useSculptorMultiFeeDistributionContract } from '../useContract'

function useStakeToken() {
  const { account, chainId } = useActiveWeb3React()
  const { SCULPT } = GET_TOKEN(chainId)

  const addTransaction = useTransactionAdder()
  const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()
  const chefIncentivesControllerContract = useSculptorChefIncentivesControllerContract()

  const [isDepositing, setIsDepositing] = useState<boolean>(false)
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false)
  const [isExpiredLocksWithdrawing, setIsExpiredLocksWithdrawing] = useState<boolean>(false)
  const [isExiting, setIsExiting] = useState<boolean>(false)
  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  const [isGettingReward, setIsGettingReward] = useState<boolean>(false)

  const deposit = useCallback(
    async (currencyAmount: CurrencyAmount<Token> | undefined, lock: boolean) => {
      if (currencyAmount && chainId) {
        try {
          setIsDepositing(true)
          const tx = await multiFeeDistributionContract?.stake(currencyAmount?.quotient.toString(), lock)
          return addTransaction(tx, { summary: lock ? 'Locked' : 'Staked' })
        } catch (e) {
          console.error('Staking error:', e)
          return e
        } finally {
          setIsDepositing(false)
        }
      }
    },
    [addTransaction, multiFeeDistributionContract, chainId]
  )

  const withdraw = useCallback(
    async (currencyAmount: CurrencyAmount<Token> | undefined) => {
      if (currencyAmount && chainId) {
        try {
          setIsWithdrawing(true)
          const tx = await multiFeeDistributionContract?.withdraw(currencyAmount?.quotient.toString())
          return addTransaction(tx, { summary: 'Unstaked' })
        } catch (e) {
          console.error('Unstaking error:', e)
          return e
        } finally {
          setIsWithdrawing(false)
        }
      }
    },
    [addTransaction, multiFeeDistributionContract, chainId]
  )

  const withdrawExpiredLocks = useCallback(async () => {
    if (chainId) {
      try {
        setIsExpiredLocksWithdrawing(true)
        const tx = await multiFeeDistributionContract?.withdrawExpiredLocks()
        return addTransaction(tx, { summary: 'Unstaked expired locks' })
      } catch (e) {
        console.error('Unstaking error:', e)
        return e
      } finally {
        setIsExpiredLocksWithdrawing(false)
      }
    }
  }, [addTransaction, multiFeeDistributionContract, chainId])

  const exit = useCallback(async () => {
    if (chainId) {
      try {
        setIsExiting(true)
        const tx = await multiFeeDistributionContract?.exit()
        return addTransaction(tx, { summary: 'Exited' })
      } catch (e) {
        console.error('Exit error:', e)
        return e
      } finally {
        setIsExiting(false)
      }
    }
  }, [addTransaction, multiFeeDistributionContract, chainId])

  const getReward = useCallback(async () => {
    if (chainId) {
      try {
        setIsGettingReward(true)
        const tx = await multiFeeDistributionContract?.getReward()
        return addTransaction(tx, { summary: 'Claimed all reward.' })
      } catch (e) {
        console.error('Claim all reward error:', e)
        return e
      } finally {
        setIsGettingReward(false)
      }
    }
  }, [addTransaction, multiFeeDistributionContract, chainId])

  const claim = useCallback(
    async (aAndVarTokens: Token[]) => {
      if (aAndVarTokens && aAndVarTokens.length > 0 && chainId) {
        try {
          setIsClaiming(true)
          const aAndVarTokenAddresses: string[] = aAndVarTokens.map((d) => d.address)
          const tx = await chefIncentivesControllerContract?.claim(account, aAndVarTokenAddresses)
          return addTransaction(tx, { summary: 'Claimed' })
        } catch (e) {
          console.error('Claiming error:', e)
          return e
        } finally {
          setIsClaiming(false)
        }
      }
    },
    [chainId, chefIncentivesControllerContract, account, addTransaction]
  )

  const useClaimableRewards = (govToken: Token): ClaimableReward[] => {
    const { chainId, account } = useActiveWeb3React()
    const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()
    const aaveOracleContract = useAaveOracleContract()
    const aTokenContractRaw = useSculptorATokenContract('0x0000000000000000000000000000000000000001')

    const defaultResponse: ClaimableReward[] = useMemo(() => {
      return []
    }, [])

    const [claimableRewards, setClaimableRewards] = useState<ClaimableReward[]>(defaultResponse)
    const fetchClaimableRewards = useCallback(async () => {
      if (account) {
        try {
          const claimableRewardsRaw = await multiFeeDistributionContract?.claimableRewards(account)

          const aTokenUnderlyingPromises: Promise<string>[] = []
          for (let i = 0; i < claimableRewardsRaw.length; i++) {
            if (claimableRewardsRaw[i].token.toLowerCase() !== govToken.address.toLowerCase()) {
              const aTokenContract = aTokenContractRaw?.attach(claimableRewardsRaw[i].token)
              aTokenUnderlyingPromises.push(aTokenContract?.UNDERLYING_ASSET_ADDRESS())
            }
          }
          const aTokenUnderlyings = await Promise.all(aTokenUnderlyingPromises)

          const aTokenPricePromises: Promise<string>[] = []
          for (let i = 0; i < aTokenUnderlyings.length; i++) {
            aTokenPricePromises.push(aaveOracleContract?.getAssetPrice(aTokenUnderlyings[i]))
          }
          const aTokenPrices = await Promise.all(aTokenPricePromises)
          let aTokenPricesIndex = 0

          const response: ClaimableReward[] = []
          for (let i = 0; i < claimableRewardsRaw.length; i++) {
            const token = AVAR_TOKENS[claimableRewardsRaw[i].token]

            let aTokenPrice
            if (token.address.toLowerCase() !== govToken.address.toLowerCase()) {
              aTokenPrice = aTokenPrices[aTokenPricesIndex++]
            }

            response.push({
              token: token,
              amount: CurrencyAmount.fromRawAmount(SCULPT, claimableRewardsRaw[i].amount),
              aTokenPrice: aTokenPrice ? new BigNumberJS(aTokenPrice.toString()).div(1e18).toNumber() : undefined,
            })
          }
          setClaimableRewards(response)
        } catch (error) {
          // setClaimableRewards(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract, govToken.address, aTokenContractRaw, aaveOracleContract, AVAR_TOKENS])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchClaimableRewards()
      } else {
        setClaimableRewards(defaultResponse)
      }
      const refreshInterval = setInterval(fetchClaimableRewards, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchClaimableRewards, multiFeeDistributionContract, defaultResponse])

    return claimableRewards
  }

  const useEarnedBalances = (token: Token): EarnedBalanceUser => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: EarnedBalanceUser = useMemo(() => {
      return {
        total: CurrencyAmount.fromRawAmount(token, 0),
        earnedBalances: [],
      }
    }, [token])

    const [earnedBalances, setEarnedBalances] = useState<EarnedBalanceUser>(defaultResponse)
    const fetchEarnedBalances = useCallback(async () => {
      if (account) {
        try {
          const earnedBalancesRaw = await multiFeeDistributionContract?.earnedBalances(account)
          const earnedBalances: LockedBalance[] = []
          for (let i = 0; i < earnedBalancesRaw.earningsData.length; i++) {
            earnedBalances.push({
              amount: CurrencyAmount.fromRawAmount(token, earnedBalancesRaw.earningsData[i][0]),
              unlockTime: earnedBalancesRaw.earningsData[i][1],
            })
          }
          setEarnedBalances({
            total: CurrencyAmount.fromRawAmount(token, earnedBalancesRaw.total),
            earnedBalances: earnedBalances,
          })
        } catch (error) {
          // setEarnedBalances(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract, token])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchEarnedBalances()
      } else {
        setEarnedBalances(defaultResponse)
      }
      const refreshInterval = setInterval(fetchEarnedBalances, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchEarnedBalances, multiFeeDistributionContract, defaultResponse])

    return earnedBalances
  }

  const useLockedBalances = (token: Token): LockedBalanceUser => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: LockedBalanceUser = useMemo(() => {
      return {
        total: CurrencyAmount.fromRawAmount(token, 0),
        unlockable: CurrencyAmount.fromRawAmount(token, 0),
        locked: CurrencyAmount.fromRawAmount(token, 0),
        lockedBalances: [],
      }
    }, [token])

    const [lockedBalances, setLockedBalances] = useState<LockedBalanceUser>(defaultResponse)
    const fetchLockedBalances = useCallback(async () => {
      if (account) {
        try {
          const lockedBalancesRaw = await multiFeeDistributionContract?.lockedBalances(account)
          const lockedBalances: LockedBalance[] = []
          for (let i = 0; i < lockedBalancesRaw.lockData.length; i++) {
            lockedBalances.push({
              amount: CurrencyAmount.fromRawAmount(token, lockedBalancesRaw.lockData[i][0]),
              unlockTime: lockedBalancesRaw.lockData[i][1],
            })
          }
          setLockedBalances({
            total: CurrencyAmount.fromRawAmount(token, lockedBalancesRaw.total),
            unlockable: CurrencyAmount.fromRawAmount(token, lockedBalancesRaw.unlockable),
            locked: CurrencyAmount.fromRawAmount(token, lockedBalancesRaw.locked),
            lockedBalances: lockedBalances,
          })
        } catch (error) {
          // setLockedBalances(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract, token])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchLockedBalances()
      } else {
        setLockedBalances(defaultResponse)
      }
      const refreshInterval = setInterval(fetchLockedBalances, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchLockedBalances, multiFeeDistributionContract, defaultResponse])

    return lockedBalances
  }

  const useAllRewardData = (govToken: Token): RewardData[] => {
    const { chainId, account } = useActiveWeb3React()
    const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()
    const aaveOracleContract = useAaveOracleContract()
    const aTokenContractRaw = useSculptorATokenContract('0x0000000000000000000000000000000000000001')

    const defaultResponse: RewardData[] = useMemo(() => {
      return []
    }, [])

    const [allRewardData, setAllRewardData] = useState<RewardData[]>(defaultResponse)
    const fetchAllRewardData = useCallback(async () => {
      if (account) {
        try {
          /*  Start useClaimableRewards */
          const claimableRewardsRaw = await multiFeeDistributionContract?.claimableRewards(account)

          const aTokenUnderlyingPromises: Promise<string>[] = []
          for (let i = 0; i < claimableRewardsRaw.length; i++) {
            if (claimableRewardsRaw[i].token.toLowerCase() !== govToken.address.toLowerCase()) {
              const aTokenContract = aTokenContractRaw?.attach(claimableRewardsRaw[i].token)
              aTokenUnderlyingPromises.push(aTokenContract?.UNDERLYING_ASSET_ADDRESS())
            }
          }
          const aTokenUnderlyings = await Promise.all(aTokenUnderlyingPromises)

          const aTokenPricePromises: Promise<string>[] = []
          for (let i = 0; i < aTokenUnderlyings.length; i++) {
            aTokenPricePromises.push(aaveOracleContract?.getAssetPrice(aTokenUnderlyings[i]))
          }
          const aTokenPrices = await Promise.all(aTokenPricePromises)
          let aTokenPricesIndex = 0

          const claimableRewards: ClaimableReward[] = []
          for (let i = 0; i < claimableRewardsRaw.length; i++) {
            const token = AVAR_TOKENS[claimableRewardsRaw[i].token]

            let aTokenPrice
            if (token.address.toLowerCase() !== govToken.address.toLowerCase()) {
              aTokenPrice = aTokenPrices[aTokenPricesIndex++]
            }

            claimableRewards.push({
              token: token,
              amount: CurrencyAmount.fromRawAmount(SCULPT, claimableRewardsRaw[i].amount),
              aTokenPrice: aTokenPrice ? new BigNumberJS(aTokenPrice.toString()).div(1e18).toNumber() : undefined,
            })
          }

          /*  End useClaimableRewards */
          const allRewardDataPromises: Promise<any>[] = []
          for (let i = 0; i < claimableRewardsRaw.length; i++) {
            allRewardDataPromises.push(multiFeeDistributionContract?.rewardData(claimableRewardsRaw[i].token))
          }
          const allRewardDataRaw = await Promise.all(allRewardDataPromises)

          const allRewardData: RewardData[] = []
          for (let i = 0; i < allRewardDataRaw.length; i++) {
            const token = claimableRewards[i].token

            allRewardData.push({
              periodFinish: allRewardDataRaw[i].periodFinish,
              rewardRate: CurrencyAmount.fromRawAmount(token, allRewardDataRaw[i].rewardRate),
              lastUpdateTime: allRewardDataRaw[i].lastUpdateTime,
              rewardPerTokenStored: CurrencyAmount.fromRawAmount(token, allRewardDataRaw[i].rewardPerTokenStored),
              balance: CurrencyAmount.fromRawAmount(token, allRewardDataRaw[i].balance),
              token: claimableRewards[i].token,
              aTokenPrice: claimableRewards[i].aTokenPrice,
            })
          }

          setAllRewardData(allRewardData)
        } catch (error) {
          // setAllRewardData(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract, govToken.address, aTokenContractRaw, aaveOracleContract, AVAR_TOKENS])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchAllRewardData()
      } else {
        setAllRewardData(defaultResponse)
      }
      const refreshInterval = setInterval(fetchAllRewardData, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchAllRewardData, multiFeeDistributionContract, defaultResponse])

    return allRewardData
  }

  const useTotalBalance = (): BigNumber => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: BigNumber = useMemo(() => {
      return BigNumber.from(0)
    }, [])
    const [totalBalance, setTotalBalance] = useState<BigNumber>(defaultResponse)
    const fetchTotalBalance = useCallback(async () => {
      if (account) {
        try {
          const totalBalanceRaw = await multiFeeDistributionContract?.totalBalance(account)
          setTotalBalance(totalBalanceRaw)
        } catch (error) {
          // setTotalBalance(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchTotalBalance()
      } else {
        setTotalBalance(defaultResponse)
      }
      const refreshInterval = setInterval(fetchTotalBalance, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchTotalBalance, multiFeeDistributionContract, defaultResponse])

    return totalBalance
  }

  const useUnlockedBalance = (token: Token): CurrencyAmount<Token> => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse = useMemo(() => {
      return CurrencyAmount.fromRawAmount(token, 0)
    }, [token])

    const [unlockedBalance, setUnlockedBalance] = useState<CurrencyAmount<Token>>(defaultResponse)
    const fetchUnlockedBalance = useCallback(async () => {
      if (account) {
        try {
          const unlockedBalanceRaw = await multiFeeDistributionContract?.unlockedBalance(account)
          setUnlockedBalance(CurrencyAmount.fromRawAmount(token, unlockedBalanceRaw))
        } catch (error) {
          // setUnlockedBalance(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract, token])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchUnlockedBalance()
      } else {
        setUnlockedBalance(defaultResponse)
      }
      const refreshInterval = setInterval(fetchUnlockedBalance, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchUnlockedBalance, multiFeeDistributionContract, defaultResponse])

    return unlockedBalance
  }

  const useWithdrawableBalance = (token: Token): WithdrawableBalance => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: WithdrawableBalance = useMemo(() => {
      return {
        amount: CurrencyAmount.fromRawAmount(token, 0),
        penaltyAmount: CurrencyAmount.fromRawAmount(token, 0),
      }
    }, [token])

    const [withdrawableBalance, setWithdrawableBalance] = useState<WithdrawableBalance>(defaultResponse)
    const fetchWithdrawableBalance = useCallback(async () => {
      if (account) {
        try {
          const withdrawableBalanceRaw = await multiFeeDistributionContract?.withdrawableBalance(account)
          const unlockedBalanceRaw = await multiFeeDistributionContract?.unlockedBalance(account)

          const amount = new BigNumberJS(withdrawableBalanceRaw.amount._hex)
            .minus(new BigNumberJS(withdrawableBalanceRaw.penaltyAmount._hex))
            .minus(new BigNumberJS(unlockedBalanceRaw._hex))

          setWithdrawableBalance({
            amount: CurrencyAmount.fromRawAmount(token, amount.toFixed(0)),
            penaltyAmount: CurrencyAmount.fromRawAmount(token, withdrawableBalanceRaw.penaltyAmount),
          })
        } catch (error) {
          // setWithdrawableBalance(defaultResponse)
          throw error
        }
      }
    }, [account, multiFeeDistributionContract, token])

    useEffect(() => {
      if (account && multiFeeDistributionContract) {
        fetchWithdrawableBalance()
      } else {
        setWithdrawableBalance(defaultResponse)
      }
      const refreshInterval = setInterval(fetchWithdrawableBalance, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchWithdrawableBalance, multiFeeDistributionContract, defaultResponse])

    return withdrawableBalance
  }

  const useRewardPerSecond = (token: Token): CurrencyAmount<Token> => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: CurrencyAmount<Token> = useMemo(() => {
      return CurrencyAmount.fromRawAmount(token, '0')
    }, [token])

    const [rewardsPerSecond, setRewardsPerSecond] = useState<CurrencyAmount<Token>>(defaultResponse)
    const fetchRewardPerSecond = useCallback(async () => {
      if (chainId) {
        try {
          const rewardsPerSecondRaw = await multiFeeDistributionContract?.rewardPerToken(token.address)
          const response = CurrencyAmount.fromRawAmount(token, rewardsPerSecondRaw)
          setRewardsPerSecond(response)
        } catch (error) {
          // setRewardsPerSecond(defaultResponse)
          throw error
        }
      }
    }, [chainId, multiFeeDistributionContract, token])

    useEffect(() => {
      if (chainId && multiFeeDistributionContract) {
        fetchRewardPerSecond()
      }
      const refreshInterval = setInterval(fetchRewardPerSecond, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchRewardPerSecond, multiFeeDistributionContract])

    return rewardsPerSecond
  }

  const useTotalStakedAmount = (token: Token): CurrencyAmount<Token> => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: CurrencyAmount<Token> = useMemo(() => {
      return CurrencyAmount.fromRawAmount(token, '0')
    }, [token])

    const [totalStakedAmount, setTotalStakedAmount] = useState<CurrencyAmount<Token>>(defaultResponse)
    const fetchTotalStakeAmount = useCallback(async () => {
      if (chainId) {
        try {
          const totalStakedAmountRaw = await multiFeeDistributionContract?.totalSupply()
          const response = CurrencyAmount.fromRawAmount(token, totalStakedAmountRaw)
          setTotalStakedAmount(response)
        } catch (error) {
          // setTotalStakedAmount(defaultResponse)
          throw error
        }
      }
    }, [chainId, multiFeeDistributionContract, token])

    useEffect(() => {
      if (chainId && multiFeeDistributionContract) {
        fetchTotalStakeAmount()
      }
      const refreshInterval = setInterval(fetchTotalStakeAmount, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchTotalStakeAmount, multiFeeDistributionContract])

    return totalStakedAmount
  }

  const useTotalLockedAmount = (token: Token): CurrencyAmount<Token> => {
    const { chainId, account } = useActiveWeb3React()
    const multiFeeDistributionContract = useSculptorMultiFeeDistributionContract()

    const defaultResponse: CurrencyAmount<Token> = useMemo(() => {
      return CurrencyAmount.fromRawAmount(token, '0')
    }, [token])

    const [totalLockedAmount, setTotalLockedAmount] = useState<CurrencyAmount<Token>>(defaultResponse)
    const fetchTotalLockedAmount = useCallback(async () => {
      if (chainId) {
        try {
          const totalLockedAmountRaw = await multiFeeDistributionContract?.lockedSupply()
          const response = CurrencyAmount.fromRawAmount(token, totalLockedAmountRaw)
          setTotalLockedAmount(response)
        } catch (error) {
          // setTotalLockedAmount(defaultResponse)
          throw error
        }
      }
    }, [chainId, multiFeeDistributionContract, token])

    useEffect(() => {
      if (chainId && multiFeeDistributionContract) {
        fetchTotalLockedAmount()
      }
      const refreshInterval = setInterval(fetchTotalLockedAmount, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchTotalLockedAmount, multiFeeDistributionContract])

    return totalLockedAmount
  }

  const useClaimableIncentiveRewards = (aAndVarTokenAddresses: string[]): ClaimableIncentiveReward[] => {
    const { chainId, account } = useActiveWeb3React()
    const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
    const aaveOracleContract = useAaveOracleContract()
    const aTokenContractRaw = useSculptorATokenContract('0x0000000000000000000000000000000000000001')

    const defaultResponse: ClaimableIncentiveReward[] = useMemo(() => {
      return []
    }, [])

    const [claimableIncentiveRewards, setClaimableIncentiveRewards] =
      useState<ClaimableIncentiveReward[]>(defaultResponse)
    const fetchClaimableIncentiveRewards = useCallback(async () => {
      if (account) {
        try {
          const claimableIncentiveRewardsRaw = await chefIncentivesControllerContract?.claimableReward(
            account,
            aAndVarTokenAddresses
          )

          const aAndVarTokenUnderlyingPromises: Promise<string>[] = []
          for (let i = 0; i < aAndVarTokenAddresses.length; i++) {
            const aTokenContract = aTokenContractRaw?.attach(aAndVarTokenAddresses[i])
            aAndVarTokenUnderlyingPromises.push(aTokenContract?.UNDERLYING_ASSET_ADDRESS())
          }
          const aAndVarTokenUnderlyings = await Promise.all(aAndVarTokenUnderlyingPromises)

          const aAndVarTokenPricePromises: Promise<string>[] = []
          for (let i = 0; i < aAndVarTokenUnderlyings.length; i++) {
            aAndVarTokenPricePromises.push(aaveOracleContract?.getAssetPrice(aAndVarTokenUnderlyings[i]))
          }
          const aAndVarTokenPrices = await Promise.all(aAndVarTokenPricePromises)

          const response: ClaimableIncentiveReward[] = []
          for (let i = 0; i < claimableIncentiveRewardsRaw.length; i++) {
            const token = AVAR_TOKENS[aAndVarTokenAddresses[i]]

            response.push({
              token: token,
              amount: CurrencyAmount.fromRawAmount(SCULPT, claimableIncentiveRewardsRaw[i]),
              // tokenPrice: new BigNumberJS(aAndVarTokenPrices[i].toString()).div(1e18).toNumber(),
            })
          }
          setClaimableIncentiveRewards(response)
        } catch (error) {
          // setClaimableIncentiveRewards(defaultResponse)
          throw error
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, JSON.stringify(aAndVarTokenAddresses), aTokenContractRaw, aaveOracleContract, AVAR_TOKENS])

    useEffect(() => {
      if (chainId && account && multiFeeDistributionContract) {
        fetchClaimableIncentiveRewards()
      } else {
        setClaimableIncentiveRewards(defaultResponse)
      }
      const refreshInterval = setInterval(fetchClaimableIncentiveRewards, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchClaimableIncentiveRewards, defaultResponse])

    return claimableIncentiveRewards
  }

  const useAllClaimableIncentiveRewards = (): ClaimableIncentiveReward[] => {
    const { chainId, account } = useActiveWeb3React()
    const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
    const chefIncentivesControllerContract = useSculptorChefIncentivesControllerContract()
    const aaveOracleContract = useAaveOracleContract()
    const aTokenContractRaw = useSculptorATokenContract('0x0000000000000000000000000000000000000001')

    const defaultResponse: ClaimableIncentiveReward[] = useMemo(() => {
      return []
    }, [])

    const [claimableIncentiveRewards, setClaimableIncentiveRewards] =
      useState<ClaimableIncentiveReward[]>(defaultResponse)
    const fetchClaimableIncentiveRewards = useCallback(async () => {
      if (account) {
        try {
          const poolLength: number = await chefIncentivesControllerContract?.poolLength()
          const registeredTokenPromises: Promise<string>[] = []
          for (let i = 0; i < poolLength; i++) {
            registeredTokenPromises.push(chefIncentivesControllerContract?.registeredTokens(i))
          }
          const aAndVarTokenAddresses: string[] = await Promise.all(registeredTokenPromises)
          const claimableIncentiveRewardsRaw = await chefIncentivesControllerContract?.claimableReward(
            account,
            aAndVarTokenAddresses
          )

          const aAndVarTokenUnderlyingPromises: Promise<string>[] = []
          for (let i = 0; i < aAndVarTokenAddresses.length; i++) {
            const aTokenContract = aTokenContractRaw?.attach(aAndVarTokenAddresses[i])
            aAndVarTokenUnderlyingPromises.push(aTokenContract?.UNDERLYING_ASSET_ADDRESS())
          }
          const aAndVarTokenUnderlyingsAddress = await Promise.all(aAndVarTokenUnderlyingPromises)

          const aAndVarTokenPricePromises: Promise<string>[] = []
          for (let i = 0; i < aAndVarTokenUnderlyingsAddress.length; i++) {
            aAndVarTokenPricePromises.push(aaveOracleContract?.getAssetPrice(aAndVarTokenUnderlyingsAddress[i]))
          }
          const aAndVarTokenPrices = await Promise.all(aAndVarTokenPricePromises)

          const response: ClaimableIncentiveReward[] = []
          for (let i = 0; i < claimableIncentiveRewardsRaw.length; i++) {
            const token = AVAR_TOKENS[aAndVarTokenAddresses[i]]

            response.push({
              token: token,
              amount: CurrencyAmount.fromRawAmount(SCULPT, claimableIncentiveRewardsRaw[i]),
              // tokenPrice: new BigNumberJS(aAndVarTokenPrices[i].toString()).div(1e18).toNumber(),
            })
          }
          setClaimableIncentiveRewards(response)
        } catch (error) {
          // setClaimableIncentiveRewards(defaultResponse)
          throw error
        }
      }
    }, [account, chefIncentivesControllerContract, aTokenContractRaw, aaveOracleContract, AVAR_TOKENS])

    useEffect(() => {
      if (chainId && account && multiFeeDistributionContract) {
        fetchClaimableIncentiveRewards()
      } else {
        setClaimableIncentiveRewards(defaultResponse)
      }
      const refreshInterval = setInterval(fetchClaimableIncentiveRewards, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchClaimableIncentiveRewards, defaultResponse])

    return claimableIncentiveRewards
  }

  return {
    deposit,
    isDepositing,
    withdraw,
    isWithdrawing,
    withdrawExpiredLocks,
    isExpiredLocksWithdrawing,
    exit,
    isExiting,
    claim,
    isClaiming,
    getReward,
    isGettingReward,
    useClaimableRewards,
    useEarnedBalances,
    useLockedBalances,
    useAllRewardData,
    useTotalBalance,
    useUnlockedBalance,
    useWithdrawableBalance,
    useRewardPerSecond,
    useTotalStakedAmount,
    useTotalLockedAmount,
    useClaimableIncentiveRewards,
    useAllClaimableIncentiveRewards,
  }
}

export interface ClaimableIncentiveReward {
  token: Token
  amount: CurrencyAmount<Token>
  /* a and var */
  // tokenPrice: number
}

export interface ClaimableReward {
  token: Token
  amount: CurrencyAmount<Token>
  /* a */
  aTokenPrice: number | undefined
}

export interface LockedBalance {
  amount: CurrencyAmount<Token>
  unlockTime: string
}

export interface EarnedBalanceUser {
  total: CurrencyAmount<Token>
  earnedBalances: LockedBalance[]
}

export interface LockedBalanceUser {
  total: CurrencyAmount<Token>
  unlockable: CurrencyAmount<Token>
  locked: CurrencyAmount<Token>
  lockedBalances: LockedBalance[]
}

export interface RewardData {
  periodFinish: string
  rewardRate: CurrencyAmount<Token>
  lastUpdateTime: string
  rewardPerTokenStored: CurrencyAmount<Token>
  balance: CurrencyAmount<Token>
  token: Token
  aTokenPrice: number | undefined
}

export interface WithdrawableBalance {
  amount: CurrencyAmount<Token>
  penaltyAmount: CurrencyAmount<Token>
}

export default useStakeToken
